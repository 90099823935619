import type { ListUsersOutput } from '@orus.eu/backend/src/routers/users'
import { ContentContainerBackoffice, spacing } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { BackofficeUsersTable, type UserSummary } from '../../../../organisms/backoffice-users-table'
import type { BackofficeUsage } from '../backoffice-contract-page/contract-page-content'

export const UsersListContent = memo<{
  listUsers: ListUsersOutput | undefined
  type: BackofficeUsage
}>(function UsersListContent({ listUsers, type }) {
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Clients</BackofficeSectionTitle>

      {listUsers ? <UsersList users={listUsers.returned} type={type} /> : <GlobalLoadingState />}
    </ContentContainerBackoffice>
  )
})

type UsersListProps = {
  users: UserSummary[]
  type: BackofficeUsage
}

function UsersList({ users, type }: UsersListProps): ReactNode {
  return <BackofficeUsersTable users={users} sx={{ marginTop: spacing[60] }} type={type} />
}
